import React from 'react'
import { Seo } from '~globals/Seo'
import Container from '~globals/Container'
import { Location } from '~components/Location'

const Page = ({
  pageContext: {
    page: { data },
    locationsCopy,
  },
}) => {

  return (
    <>
      <Container>
        <Location
          copy={locationsCopy.data}
          data={data}
          larger
        />
      </Container>
    </>
  )
}

export default Page

export const Head = ({
  pageContext: {
    page: { data },
  },
}) => {
  const { title } = data

  return (
    <Seo
      sharingTitle={`${title.text} | Shiso Burger`}
    />
  )
}

